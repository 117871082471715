<template>
  <div class="manage-property-detail">
    <div class="container fluid">
      <div class="card p-2 md-p-3">
        <!-- Title Section -->
        <div class="d-flex align-items-center mb-5">
          <h1>Property Details</h1>
        </div>
        <!-- Details -->
        <property-detail
          ref="propertyDetail"
          :propertyDetails="propertyDetails"
          :isLoading="isPropertyDetailLoading"
          :canEdit="canEdit || isSelf"
          @toListPage="toPropertyList"
          @toExclusive="toExclusivePage"
          @repost="repostProperty"
          @archiveRent="openArchiveRentModal"
          @activateACN="openActivateACNModal"
          @toManageACN="toACNDetail"
          @linkOwner="openLinkOwnerModal"
        ></property-detail>
      </div>
    </div>
    <!-- ============================= Modals ============================== -->
    <modal v-model="archiveRent.isModalShown" persistent fullscreen>
      <archive-rent-form
        class="card sharp"
        :property="archiveRent.property"
        @close="archiveRent.isModalShown = false"
      ></archive-rent-form>
    </modal>
    <modal v-model="acnActivation.isModalShown">
      <div class="card p-2">
        <h3 class="mb-4">Activate ACN</h3>

        <AdjustRegistrantRate
          :property="propertyDetails"
          submitButtonLabel="Activate"
          @submit="activateACN"
          @close="acnActivation.isModalShown = false"
        ></AdjustRegistrantRate>
      </div>
    </modal>
    <modal v-model="ownerLinking.isModalShown">
      <div class="card p-2">
        <h3 class="mb-4">Link Owner</h3>

        <div
          v-if="propertyDetails.belongToOwner"
          class="p-2 mb-2 bg-blue-700 fg-white"
          style="border-radius: 4px"
        >
          Currently linked to {{ propertyDetails.belongToOwner.contact }}.
          <br />
          Warning: Re-link will overwrite the current owner.
        </div>
        <LinkOwner
          @submit="linkOwner"
          @close="ownerLinking.isModalShown = false"
        ></LinkOwner>
      </div>
    </modal>
  </div>
</template>

<script>
import PropertyDetail from "@/components/GlobalComponents/Shared/Property/PropertyDetail";

export default {
  components: {
    PropertyDetail,
    ArchiveRentForm: () =>
      import("@/modules/Rent/components/ArchiveRent/ArchiveRentForm"),
    AdjustRegistrantRate: () =>
      import("@/modules/ACN/components/AdjustRegistrantRate"),
    LinkOwner: () => import("@/modules/Property/components/LinkOwner")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      canEdit: false,

      // Property Details
      isPropertyDetailLoading: false,
      propertyDetails: {},
      // Archive Rent
      archiveRent: {
        property: {},
        isModalShown: false
      },
      // Activate ACN
      acnActivation: {
        isModalShown: false
      },
      // Link Owner
      ownerLinking: {
        isModalShown: false
      }
    };
  },
  computed: {
    isSelf() {
      return this.propertyDetails.agencyUser.user.id == this.$auth.user().id;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAccessPermission();
      await this.getProperty();
    },
    async getAccessPermission() {
      this.canEdit = await this.$store.dispatch(
        "manageAccount/checkPermission",
        "property.update"
      );
    },
    toPropertyList() {
      this.$router.push({
        name: "ManageProperty"
      });
    },
    toExclusivePage(id) {
      this.$router.push({
        name: "ManagePropertyExclusive",
        params: {
          id: id
        }
      });
    },
    toACNDetail() {
      this.$router.push({
        name: "ACNDetail",
        params: {
          id: this.propertyDetails.acnGroup.id
        }
      });
    },
    openArchiveRentModal(property) {
      this.archiveRent.property = property;
      this.archiveRent.isModalShown = true;
    },
    openActivateACNModal() {
      this.acnActivation.isModalShown = true;
    },
    openLinkOwnerModal() {
      this.ownerLinking.isModalShown = true;
    },
    // ========================== API RELATED ==============================
    async getProperty() {
      let action =
        this.$route.params.type == "agency"
          ? "manageProperty/getAgencyProperty"
          : "manageProperty/getMyProperty";
      try {
        this.isPropertyDetailLoading = true;

        let data = await this.$store.dispatch(action, {
          id: this.$route.params.id
        });
        this.propertyDetails = this._.cloneDeep(data);
        this.$nextTick(() => {
          this.$refs.propertyDetail.initImages();
          this.isPropertyDetailLoading = false;
        });
      } catch (error) {
        this.isPropertyDetailLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch property details. Please try again later."
        });
      }
    },
    async repostProperty(id) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/repostProperty", id);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Listing has been updated."
        });
        this.$store.commit("setIsLoading", false);
        await this.getProperty();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to repost listing. Please try again later."
        });
      }
    },
    async activateACN(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/activatePropertyACN", {
          id: this.$route.params.id,
          payload: payload
        });
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "ACN Activated",
          text: "Property's ACN has been activated sucessfully."
        });
        this.acnActivation.isModalShown = false;

        this.getProperty();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to activate listing for ACN. Please try again later."
        });
      }
    },
    async linkOwner(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.$store.dispatch("manageProperty/linkOwner", {
          id: this.$route.params.id,
          payload: payload
        });
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Owner Linked",
          text: "Owner has been linked to the property"
        });
        this.ownerLinking.isModalShown = false;

        this.getProperty();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Failed to link owner",
          text: error.response.data.message
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
